import { css } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { colors } from '../../theme/colors';

type Props = {
  node: {
    slug: string | null;
    intervieweeImage: IGatsbyImageData | null | undefined;
    companyName: string | null;
    title: string | null;
    companyEmployee: string | null;
    companyIndustry: string | null;
    category: readonly (string | null)[] | null;
  };
  isTop?: boolean;
};

export const UserstoryCard: FC<Props> = ({ node, isTop }) => {
  return (
    <Link to={`/userstory/${node.slug}/`}>
      <div css={UserstoryCardStyle(isTop)}>
        <div css={ImageWrapperStyle(isTop)}>
          <GatsbyImage
            image={node.intervieweeImage!}
            alt={node.companyName || ''}
            css={ImageWrapperStyle(isTop)}
            className='userstory-thumbnail'
          />
        </div>

        <h2 css={TitleStyle}>{node.title}</h2>
        <div css={TextWrapperStyle}>
          <p className='company-name'>{node.companyName}</p>
          <p>従業員数：{node.companyEmployee}</p>
        </div>

        <ul css={TagWrapperStyle}>
          <li css={CardTagStyle}># {node.companyIndustry}</li>
          {node.category &&
            node.category.map((tag, index) => {
              return (
                <li key={index} css={CardTagStyle}>
                  # {tag}
                </li>
              );
            })}
        </ul>
      </div>
    </Link>
  );
};
const UserstoryCardStyle = (isTop?: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    maxWidth: 335,

    '@media(max-width: 1119px)': {
      fontSize: 16,
      minHeight: 0,
      maxWidth: isTop ? 288 : 335,
    },

    '& .userstory-thumbnail': {
      transition: 'transform 0.3s',
    },

    '&:hover& .userstory-thumbnail': {
      transform: 'scale(1.05, 1.05)',
    },

    '&:hover& h2': {
      color: colors.primary,
    },
  });

const ImageWrapperStyle = (isTop?: boolean) =>
  css({
    maxWidth: 335,
    height: 240,
    borderRadius: 16,
    overflow: 'hidden',

    img: {
      borderRadius: 16,
    },

    '@media(max-width: 1119px)': {
      height: isTop ? 206 : 240,
    },
  });

const TitleStyle = css({
  fontSize: 20,
  fontWeight: 700,
  color: colors.black,
  minHeight: 90,
  transition: 'color 0.3s',

  '@media(max-width: 1119px)': {
    fontSize: 16,
    minHeight: 0,
  },
});

const TextWrapperStyle = css({
  color: colors.gray,
  lineHeight: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

const TagWrapperStyle = css({
  display: 'flex',
  listStyle: 'none',
  gap: 8,
});

const CardTagStyle = css({
  fontSize: 12,
  border: `2px solid ${colors.primary}`,
  padding: '6px 10px',
  borderRadius: 24,
  color: colors.primary,
  lineHeight: 1,
});
