import { css } from '@emotion/react';
import { FC } from 'react';

import { colors } from '../../theme/colors';

type TagCategory = { category: string; tagList: string[] };

type Props = {
  tagCategoryList: TagCategory[];
  activeTagList: string[];
  onClickTag: (tagName: string) => void;
  onClickAll: (tagList: string[]) => void;
};

export const TagFilter: FC<Props> = ({
  tagCategoryList,
  activeTagList,
  onClickTag,
  onClickAll,
}) => {
  return (
    <div css={TagFilterContainerStyle}>
      <div css={TagFilterStyle}>
        {tagCategoryList.map((tagCategory, index) => {
          return (
            <div
              key={`category_${index}`}
              css={[
                TagCategoryStyle,
                { borderTop: index > 0 ? `1px solid ${colors.black}` : '' },
              ]}
            >
              <p css={TagCategoryTitleStyle}>{tagCategory.category}</p>
              <div css={TagWrapperStyle}>
                <button
                  css={TagStyle(
                    !activeTagList.some((value) =>
                      tagCategory.tagList.includes(value)
                    )
                  )}
                  onClick={() => onClickAll(tagCategory.tagList)}
                >
                  #全て
                </button>
                {tagCategory.tagList.map((tag, index) => {
                  return (
                    <button
                      key={`tag_${index}`}
                      css={TagStyle(activeTagList.includes(tag))}
                      onClick={() => onClickTag(tag)}
                    >
                      #{tag}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TagFilterContainerStyle = css({
  width: '100%',
  maxWidth: 1200,
  padding: '0 28px',
  display: 'flex',
  justifyContent: 'center',

  '@media(max-width: 1119px)': {
    padding: '40px 17px',
  },
});

const TagFilterStyle = css({
  width: '100%',
  padding: '8px 48px',
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${colors.black}`,
  borderRadius: 16,

  '@media(max-width: 1119px)': {
    padding: '6px 30px',
  },
});

const TagCategoryStyle = css({
  display: 'flex',

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const TagCategoryTitleStyle = css({
  minWidth: 104,
  marginRight: 20,
  padding: '37px 0',
  fontSize: 26,
  fontWeight: 700,
  lineHeight: 1,
  whiteSpace: 'nowrap',
  color: colors.black,

  '@media(max-width: 1119px)': {
    padding: '24px 0',
  },
});

const TagWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: 30,
  rowGap: 8,
  columnGap: 12,
  flexWrap: 'wrap',

  '@media(max-width: 1119px)': {
    padding: '0 0 30px',
  },
});

const TagStyle = (active: boolean) =>
  css({
    fontSize: 16,
    height: 40,
    lineHeight: 1,
    padding: '10px 18px',
    background: active ? colors.primary : colors.white,
    borderRadius: 100,
    color: active ? colors.white : colors.primary,
    border: `solid 2px ${colors.primary}`,
    transition: '0.3s',

    '&:hover': {
      filter: 'brightness(1.1)',
    },

    '&:active': {
      filter: 'brightness(0.9)',
    },

    '@media(max-width: 1119px)': {
      fontSize: 12,
      fontWeight: 700,
    },
  });
