import { css } from '@emotion/react';
import { useState, FC } from 'react';
import { graphql } from 'gatsby';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleListHeading } from '../../components/organisms/ArticleListHeading';
import { TagFilter } from '../../components/organisms/TagFilter';
import { UserstoryCard } from '../../components/userstory/UserstoryCard';

export const Head = () => (
  <HeadInner
    path='/userstory/'
    title='導入事例'
    description='paild（ペイルド）の導入事例です。paildを活用し、自腹立替、小口現金、仮払いをなくして、法人支出の最適化を実現した事例を紹介します'
  />
);

const UserStoryPage: FC<{
  data: GatsbyTypes.UserstoryIndexQueryQuery;
}> = (props) => {
  const [activeTagList, setActiveTagList] = useState<string[]>([]);

  const onClickTag = (tagName: string) => {
    setActiveTagList((prev) =>
      prev.includes(tagName)
        ? prev.filter((prevValue) => prevValue !== tagName)
        : [...prev, tagName]
    );
  };

  const onClickAll = (tagList: string[]) => {
    setActiveTagList((prev) =>
      prev.filter((prevValue) => {
        return !tagList.includes(prevValue);
      })
    );
  };

  const [page] = useState(0);

  const perPage = 12;
  const allUserstoryList = props.data.allContentfulUserstory.nodes;

  // userstoryのリストからタグに該当しているものを取り出し、使いやすい形にmapしてからpriority順に並び替え
  const userstoryNodes = allUserstoryList
    .filter((node) =>
      activeTagList.length
        ? activeTagList.some(
            (value) =>
              value === node.companyIndustry || node.category?.includes(value)
          )
        : node
    )
    .slice(page * perPage, (page + 1) * perPage)
    .sort((a, b) => (a.priority! > b.priority! ? -1 : 1))
    .map((node) => {
      return {
        slug: node.slug,
        intervieweeImage: node.intervieweeImage?.gatsbyImageData,
        companyName: node.companyName,
        title: node.title,
        companyEmployee: node.companyEmployee,
        companyIndustry: node.companyIndustry,
        category: node.category,
      };
    });

  const tagCategoryList: { category: string; tagList: string[] }[] = [
    {
      category: '業種',
      tagList: allUserstoryList
        .map((node) => {
          return node.companyIndustry;
        })
        .filter(
          (value, index, array): value is NonNullable<typeof value> =>
            value !== null && array.indexOf(value) === index
        ),
    },
    {
      category: '導入理由',
      tagList: allUserstoryList
        .map((node) => {
          return node.category;
        })
        .flat()
        .filter(
          (value, index, array): value is NonNullable<typeof value> =>
            value !== null && array.indexOf(value) === index
        ),
    },
  ];

  const links = [{ name: '導入事例', path: '/userstory/' }];

  return (
    <Layout links={links}>
      <ArticleListHeading
        category='userstory'
        title='導入事例'
        description={[
          'paildを導入いただいているみなさまに、インタビュー形式でお話を伺いました。',
        ]}
      />
      <TagFilter
        tagCategoryList={tagCategoryList}
        activeTagList={activeTagList}
        onClickTag={onClickTag}
        onClickAll={onClickAll}
      />
      <section css={UserstoryListSectionStyle}>
        {userstoryNodes.map((node, index) => {
          return <UserstoryCard key={index} node={node} />;
        })}
        {[...Array(3 - (userstoryNodes.length % 3))].map((_, index) => {
          return <div key={index} css={CardSpaceStyle} />;
        })}
      </section>
    </Layout>
  );
};

const UserstoryListSectionStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: 1200,
  padding: '24px 28px',
  rowGap: 50,

  '@media(max-width: 1119px)': {
    justifyContent: 'center',
    gap: 50,
  },

  '@media(max-width: 768px)': {
    padding: 17,
  },
});

const CardSpaceStyle = css({
  width: '100%',
  maxWidth: 340,
});

export const query = graphql`
  query UserstoryIndexQuery {
    allContentfulUserstory {
      nodes {
        id
        slug
        title
        intervieweeImage {
          title
          description
          gatsbyImageData(placeholder: NONE)
        }
        companyName
        companyIndustry
        companyEmployee
        category
        priority
      }
    }
  }
`;

export default UserStoryPage;
