import { css } from '@emotion/react';
import { FC } from 'react';

import { colors } from '../../theme/colors';

import { StaticImage } from 'gatsby-plugin-image';

type Props = {
  category:
    | 'feature'
    | 'userstory'
    | 'news'
    | 'documents'
    | 'seminar'
    | 'partner';
  title: string;
  description?: string[];
};

export const ArticleListHeading: FC<Props> = ({
  category,
  title,
  description,
}) => {
  return (
    <div css={ArticleListHeadingStyle}>
      <div css={ArticleListHeadingWrapperStyle}>
        <div css={TextWrapperStyle}>
          <p css={CategoryStyle}>{category}</p>
          <h2 css={TitleStyle}>{title}</h2>
          <div>
            {description?.map((text, index) => {
              return (
                <p key={index} css={DescriptionStyle}>
                  {text}
                </p>
              );
            })}
          </div>
        </div>
        <div css={ArticleListHeadingImageWrapperStyle}>
          {category === 'feature' && (
            <StaticImage
              src='../../images/heading/function.svg'
              alt='function'
              width={526}
              quality={100}
              placeholder='none'
            />
          )}
          {category === 'userstory' && (
            <StaticImage
              src='../../images/heading/user-story.svg'
              alt='user-story'
              width={526}
              quality={100}
              placeholder='none'
            />
          )}
          {category === 'news' && (
            <StaticImage
              src='../../images/heading/news.svg'
              alt='news'
              width={526}
              quality={100}
              placeholder='none'
            />
          )}
          {category === 'documents' && (
            <StaticImage
              src='../../images/heading/documents.svg'
              alt='documents'
              width={526}
              quality={100}
              placeholder='none'
            />
          )}
          {category === 'seminar' && (
            <StaticImage
              src='../../images/heading/seminar.svg'
              alt='seminar'
              width={526}
              quality={100}
              placeholder='none'
            />
          )}
          {category === 'partner' && (
            <StaticImage
              src='../../images/heading/partner.svg'
              alt='partner'
              width={526}
              quality={100}
              placeholder='none'
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ArticleListHeadingStyle = css({
  width: 'calc(100% - 2px)',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',

  '@media(max-width: 1119px)': {
    minHeight: 500,
  },
});

const ArticleListHeadingWrapperStyle = css({
  position: 'relative',
  width: 1800,
  background: colors.secondary,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 40,
});

const TextWrapperStyle = css({
  width: '100%',
  maxWidth: 1200,
  height: 376,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  padding: '80px 28px 0',

  '@media(max-width: 1119px)': {
    maxWidth: 576,
    height: 'auto',
    padding: '40px 14px 0',
  },
});

const CategoryStyle = css({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1,
  color: colors.primary,
  textTransform: 'capitalize',

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const TitleStyle = css({
  fontSize: 48,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.black,
  marginBottom: 36,

  '@media(max-width: 1119px)': {
    fontSize: 28,
    marginBottom: 6,
  },
});

const DescriptionStyle = css({
  fontSize: 16,
  color: colors.black,
  lineHeight: 2,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const ArticleListHeadingImageWrapperStyle = css({
  width: '100%',
  maxWidth: 1300,
  position: 'absolute',
  top: 30,
  display: 'flex',
  justifyContent: 'flex-end',

  '@media(max-width: 1119px)': {
    position: 'static',
    justifyContent: 'center',
    padding: '0 24px',
    maxWidth: 480,
  },
});
